/* @tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

canvas {
  background-color: transparent;
}

:root {
  --primary: #8259a8; /* 변수 정의 */
  --primaryText: #b576ed;
  font-family: "normal_kirakira";
}

a {
  text-decoration: none;
  color: #0095f4;
}

.link {
  text-decoration: none;
  color: var(--primary);
}
.slide-container {
  display: flex;
  padding: 0;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}
/* .div {

  font-family: "normal_kirakira";
} */

.title {
  display: flex;
  padding-bottom: 8px;

  width: 100%;
  justify-content: center;

  font-family: "normal_kirakira";
  font-size: 1.5rem;
  /* //bold */
  font-weight: 600;
  color: #474747;

  padding-top: 15px;
  padding-bottom: 30px;
}
.commonNormalBtn {
  width: 100% !important;
  height: 32px !important;
  padding: 5px !important;
  margin-top: 7px !important;
  color: var(--primaryText) !important;
  border-color: var(--primaryText) !important;
  background-color: #ffffff !important;
  font-family: "normal_kirakira";
}
.commonMidBtn {
  width: 50% !important;
  height: 50px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background-image: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%) !important;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3) !important;
  font-family: "normal_kirakira";
}
.blockedCommonMidBtn {
  width: 50% !important;
  height: 50px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background-image: linear-gradient(45deg, #545454 30%, #acacac 90%) !important;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3) !important;
  font-family: "normal_kirakira";
}
.commonFullBtn {
  width: 100% !important;
  height: 50px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background-image: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%) !important;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3) !important;
  font-family: "normal_kirakira";
}
.commonFullPreorderBtn {
  width: 100% !important;
  height: 50px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background-image: linear-gradient(
    45deg,
    #8259a8 30%,
    #ffe866 110%
  ) !important;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3) !important;
  font-family: "normal_kirakira";
}
.commonOutlinedBtn {
  background-color: #ffffff !important;
  border-color: var(--primaryText) !important;
  border: 2;
  color: var(--primaryText) !important;
  width: 90%;
  padding: 5px !important;
  margin: 5px !important;
  font-family: "normal_kirakira";
}
.commonCenterDiv {
  text-align: center;
  font-size: 0.8rem;
  padding: 5px !important;
}
.commonBtn {
  /* background-color: #ffffff !important; */

  size: small !important;
  padding: 5px !important;
  margin: 5px !important;
  font-family: "normal_kirakira";
}

.slide-item {
  flex-shrink: 0;
  padding: 5px;
  margin: 0;
  margin-right: 5px;
  /* width: 40%; */
  /* scroll-snap-align: center; */
}

.commonDiv {
  display: block;
  font-size: 0.8rem;
  color: #474747;
  padding: 5px;
  line-height: 2;
}

/* //=============================================================== */
.parentBox {
  margin: 5px;
  display: block;
}

.titleDiv {
  font-family: "normal_kirakira_bold";
  background-color: #ffb400;

  text-shadow: 0px 0px 6px rgb(75, 44, 89);

  padding: 7px;

  display: flex;
  text-align: center;
  font-size: 0.8rem;
  vertical-align: center !important;

  color: #ffffff;
  justify-content: center;
  border-radius: 25px;
  margin: 25px;
  margin-bottom: 10px;
  margin-top: 15px;
  /* margin-top: 20px; */
}
.divider {
}
/* //=============================================================== */

.elementBox {
  width: 100%;
  display: inline-block;
  /* border-color: #848484; */
  /* padding: 10px; */
  /* background-color: #f9f9f9; */
}
.elementBoxSub {
  width: 100%;
  display: inline-block;
  /* border-color: #848484; */
  /* padding: 5px; */
  background-color: #f9f9f9;
}
/* //=============================================================== */

.normalSpan {
  font-family: "normal_kirakira";
  font-size: 0.9rem;
  color: #474747;
  padding: 0px;
}
.normalSpanSub {
  font-family: "normal_kirakira";
  font-size: 0.7rem;
  color: #474747;
  /* padding: 5px; */
}
/* //=============================================================== */

.appbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #fff;
  z-index: 999;
  transition: opacity 0.2s ease-out;
}

.appbar-show {
  opacity: 1;
}

.appbar-hide {
  opacity: 0;
  pointer-events: none;
}

@font-face {
  font-family: "normal_kirakira_bold"; /* 웹 폰트의 이름을 지정합니다. */
  /*bold*/
  src: url("/res/fonts/headline/Title_Medium.otf") format("opentype");
  /* src: url("/res/fonts/nanum/NanumSquareNeoTTF-dEb.woff") format("woff"); WOFF 파일 경로를 지정합니다. */
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "normal_kirakira"; /* 웹 폰트의 이름을 지정합니다. 
  src: url("/res/fonts/nanum/NanumSquareNeoTTF-bRg.woff") format("woff");  WOFF 파일 경로를 지정합니다. 
  font-weight: normal;
  font-style: normal;
} */
/* .normal_font {
  font-size: 0.8rem;
  font-family: "normal_kirakira";
}

.bold_font {
  font-size: 0.8rem;
  font-family: "normal_kirakira_bold";
} */
body {
  font-family: "normal_kirakira";
  color: rgb(75, 44, 89);
  /* font-family: "normal_kirakira"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.05em; /* 조정값은 상황에 따라 다를 수 있습니다. */

  /* //배경컬러 */
  background-image: url(../res/drawable/bg6-1.jpg);

  /* //배경 */
  background-color: #d0cad8;
  /* //폰트컬러 */

  margin: 0;
  /* margin: 2px; */
}
/* 숫자 주위의 링크 스타일 제거 */
a[href^="tel"] {
  text-decoration: none;
  color: inherit;
}

/* .description {
  font-family: "normal";
  font-size: 0.9rem;
} */

@font-face {
  font-family: "headline1";
  src: url("/res/fonts/headline/JalnanOTF.otf") format("opentype");
}

/* @font-face {
  font-family: "normal";
  src: url("/res/fonts/Leferi/LeferiBaseRegular.otf") format("opentype");
} */

@font-face {
  font-family: "normal_kirakira";
  src: url("/res/fonts/headline/Title_Light.otf") format("opentype");
  /* src: url("/res/fonts/Leferi/LeferiPointWhite.otf") format("opentype"); */
}

.menuText {
  color: #474747;
}

/* Custom styles for pagination */
.swiper-pagination-bullet {
  background-color: #ff0000; /* Replace with your desired color */
}

.swiper-pagination-bullet-active {
  background-color: #00ff00; /* Replace with your desired color for active pagination bullet */
}

/* .pagination-container {
  position: relative;
}

.swiper-pagination {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px 0;
} */

.centerContainer {
  /* //=====중앙 정렬========= */
  justify-content: center !important;
  text-align: center;
  /* //=====중앙 정렬========= */
}

.App {
  /* display: "block"; */
  font-family: "normal_kirakira" !important;

  background-image: url(../res/drawable/bg7.png);
  background-size: 100vw;
  /* background-attachment: fixed; */
  background-repeat: repeat;
  /* background-size: "cover"; */
  background-position: center center;

  width: 100%;
  height: auto;

  /* overflow: hidden; */

  /* margin-bottom: 30px; */
}

.primaryButton {
  color: #ffffff;
  background-color: var(--primary);
  font-family: "normal_kirakira";
}
.commonButton {
  color: #ffffff;
  font-family: "Arial";
  background-color: #000000;
  font-family: "normal_kirakira";
}

.indexContainer {
  background-color: #000000;
  margin: 0;
  padding: 0;
}
.addContainer {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}

/* //============= 가로로 내부에서 쪼갤 때 사용 ============= */
.container {
  width: 100%;
  display: flex;
  font-family: "normal_kirakira";
}
.item {
  margin: 3px;
  font-family: "normal_kirakira";
}
/* //======================================================= */
.editForm {
  margin: 3px;
}

.centerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3px;
  font-family: "normal_kirakira";
}

/* div {
  margin: 3px;
} */

.MuiButton-root {
  padding: 3px;
  font-family: "normal_kirakira";
}
.MuiTextField-root {
  margin: 3px;
  font-family: "normal_kirakira";
}
.MuiImageListItemBar-titleWrap {
  padding: 3px !important;
  font-family: "normal_kirakira";
}

.MuiTypography-root {
  font-family: "normal_kirakira" !important;
}
.MuiChip-label {
  font-family: "normal_kirakira" !important;
}
.MuiButtonBase-root {
  font-family: "normal_kirakira_bold" !important;
}

/* 좋아요 이모티콘용 애니메이션 */

.heart-button {
  /* position: relative; */
  width: 30px; /* 버튼 크기 */
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center; /* 텍스트 중앙 정렬 */
}

.heart-button:active {
  transform: scale(0.5);
}

.heart-icon {
  position: absolute;
  width: 30px; /* 하트 크기 */
  height: 30px;
  background-color: #ffb9d0;
  transform: rotate(45deg);
  z-index: 1; /* 텍스트 뒤로 배치 */
}

.heart-icon::before,
.heart-icon::after {
  content: "";
  position: absolute;
  width: 30px; /* 하트 크기 */
  height: 30px;
  background-color: #ffb9d0;
  border-radius: 50%;
}

.heart-icon::before {
  top: -15px;
  left: 0;
}

.heart-icon::after {
  top: 0;
  left: -15px;
}

.heart-text {
  position: relative;
  z-index: 2; /* 텍스트가 하트 위에 표시되도록 설정 */
  font-size: 12px;
  color: white; /* 텍스트 색상 */
  font-weight: bold;
  text-align: center;
}
.heart-container {
  position: relative;
  width: 60px; /* 클릭 영역 크기 */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent; /* 클릭 영역을 시각적으로 숨김 */
  border-radius: 10px; /* 약간의 둥근 모서리 */
}

.heart-float {
  position: absolute;
  width: 16px; /* 떠오르는 하트 크기 */
  height: 16px;
  background-color: red;
  transform: rotate(-45deg);
  animation: float 1.5s ease-out forwards; /* 애니메이션 지속 시간 1.5초로 증가 */

  /* border: 1px solid rgba(0, 0, 0, 0.2); */
}

.heart-float::before,
.heart-float::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: inherit;
  border-radius: 50%;
}

.heart-float::before {
  top: -8px;
  left: 0;
}

.heart-float::after {
  left: 8px;
  top: 0;
}
@keyframes float {
  0% {
    transform: translateY(0) rotate(-45deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-180px) rotate(-45deg); /* 사라지는 높이를 기존의 1.8배로 증가 */
    opacity: 0;
  }
}
